import styles from './Button.module.scss'

interface ButtonLinkProps<T> {
	children?: React.ReactNode
	className?: string
	linkAs?: React.ComponentType<T>
	style?: React.CSSProperties
	dataTestId?: string
	preventClick?: boolean
	onClick?: (event: React.MouseEvent) => void
	id?: string
	linkAttributes?: Omit<T, 'children'>
	disabled?: boolean
	label?: string
	secondary?: boolean
}

/**
 * @deprecated use specific button types instead: 'fukku/ButtonContentLink'
 */
export function ButtonLink<T>(props: ButtonLinkProps<T>) {
	const {
		children,
		className,
		linkAs: LinkComponent = 'a',
		style,
		dataTestId,
		preventClick,
		onClick = () => {},
		id,
		linkAttributes,
		disabled,
		label,
	} = props

	const handleClickAnchor = (e: React.MouseEvent): void => {
		if (preventClick) {
			e.preventDefault()
			e.stopPropagation()
		} else {
			onClick(e)
		}
	}

	return (
		<LinkComponent
			className={className}
			style={style}
			data-testid={dataTestId}
			id={id}
			onClick={handleClickAnchor}
			aria-disabled={disabled}
			aria-label={label}
			{...(linkAttributes as T)}
		>
			{children && <span className={styles.content}>{children}</span>}
		</LinkComponent>
	)
}
